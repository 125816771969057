body {
	background-image: linear-gradient(#f2efe1, #fff);
	background-repeat: no-repeat;
	background-size: 100% 2000px;
}

h1 {
	padding-top: 10px;
}

.ui.items.segment,
.ui.card  {
	background-color: rgba(250, 250, 250, 0.2);
}

.ui.card.centered {
	margin-bottom: 0px;
}

.ui.transparent.input {
	border-bottom: 1px black solid;
}

input, .ui.transparent.input, .ui.form {
	background-color: rgba(250, 250, 250, 0.0);
}

.link-icons {
	height: 20px;	
	transform: translate(0,.21em);
}

.wiki-icon {
	height:22px;
}

img .lastfm-icon {
	font-size: 20px;
}

#info-tip {
	max-width: 250px;
}